body {
    background-color: white;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1vh;
  margin-top: 1vh;
  margin-right: 14px;
}
.thumbs {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 23px;
    /* top: -2vh; */
    margin: 0;
    padding: 0;
}
/* h3, .thumbs, span {
  margin: 0;
  padding: 0;
} */
.thumbs-up:hover, .thumbs-down:hover {
    transform: scale(1.2);
  }
.thumbs-up.blue, .thumbs-down.blue {
    color: #767471;
    /* color: #538fdf; */
  }
  h3 {
    margin-bottom: 0;
    padding-bottom: 0;
  }
span {
    font-size: 18px;
    user-select: none;

}
@media screen and (max-width: 600px) {
  .homePage .post {
    /* Adjust styles for smaller screens */
    width: 100%; /* Make posts take full width */
    font-size: 0.8em;
    overflow-x: hidden;
    /* Add more adjustments as needed */
  }
}
